<template>
  <div class="login">
    <div class="form_warp">
      <div class="left_img">
        <img src="@/assets/login/ca.png"
             alt="">
      </div>
      <div class="right_form"
           v-if="activeStep === 1">
        <div class="back_left">
          <img src="@/assets/teachterCard/Left.png"
               @click="back"
               alt="">
        </div>
        <div class="block">
          <div class="title">
            教师卡激活
          </div>
        </div>
        <div class="form">
          <div class="custom-cascader">
            <el-select v-model="value"
                       placeholder=""
                       @change="handleSelectChange">
              <el-option v-for="item in options"
                         :key="item.value"
                         :label="item.label"
                         :value="item.value">
              </el-option>
            </el-select>
            <div class="warp">
              <div class="line"
                   style="margin-top:16px">
                <el-input clearable
                          v-model="form.account"
                          placeholder="请输入教师卡卡号">
                </el-input>
              </div>
              <div class="line"
                   style="margin-top:16px">
                <el-input clearable
                          placeholder="请输入教师卡密码"
                          type="password"
                          v-model="form.password"
                          show-password>
                </el-input>

              </div>
              <el-button type="primary"
                         class="nxet-btn1"
                         @click="activation"
                         :disabled="!form.account || !form.password">下一步
              </el-button>
            </div>
          </div>

        </div>

      </div>
      <Bindphone v-if="activeStep === 2"
                 @Back="to"
                 @step-success="goToStep3"></Bindphone>
      <Information v-if="activeStep === 3"
                   @Back="to"
                   @step-successNext="goToStep3"></Information>
      <Success v-if="activeStep === 4"></Success>
    </div>
  </div>
</template>

<script>
import activation from "./activation"
import Bindphone from '@/views/teacherCard/components/Bindphone.vue'
import Information from '@/views/teacherCard/components/Information'
import Success from '@/views/teacherCard/components/Success'
export default {
  data () {
    return {
      isLoading: false,
      activeStep: 1,
      options: [
        {
          value: "初中",
          label: "初中",
        },
        {
          value: "高中",
          label: "高中",
        },
      ],
      value: '初中',
      form: {
        account: '',
        password: '',
      },

    }
  },
  components: {
    Bindphone,
    Information,
    Success
  },
  name: 'teacherCard',
  mixins: [activation]
}
</script>
<style lang="scss"  scoped>
.login {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../../assets/teachterCard/bg.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  .form_warp {
    width: 1200px;
    height: 600px;
    background-color: white;
    border-radius: 20px;
    box-shadow: 0px 2px 14px 4px rgba(141, 184, 227, 0.3);
    display: flex;
    .left_img {
      width: 50%;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    .right_form {
      width: 50%;
      overflow: hidden;
      .back_left {
        padding: 16px 0 0 16px;
        box-sizing: border-box;
        cursor: pointer;
        display: inline-block;
      }
      .title {
        display: flex;
        justify-content: center;
        height: 39px;
        font-size: 28px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 600;
        color: #1a1a1a;
        margin-top: 10px;
        // display: inline;
      }

      .form {
        display: flex;
        justify-content: center;
        margin-top: 53px;
        .custom-cascader {
          .el-cascader__dropdown {
            width: 100%;
            max-height: 100%;
          }
        }
        .warp {
          width: 100%;
          max-width: 390px;

          .line {
            width: 100%;
            // border-bottom: 1px solid #eeeeee;
          }
        }
      }
    }
  }
}

::v-deep .el-input__prefix {
  margin-top: 11px;
}

::v-deep .el-input__inner {
  font-size: 14px !important;
  padding: 0 4px;
}
::v-deep .el-input--medium .el-input__inner {
  height: 48px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0%;
  line-height: 40px;
}
::v-deep .el-select {
  width: 100%;
  // border-bottom: 1px solid #eeeeee;
}
::v-deep .nxet-btn1 {
  margin: 0 auto;
  width: 336px;
  height: 48px;
  cursor: pointer;
  margin-top: 40px;
  border-radius: 5px;
  font-size: 16px;
}
</style>