// 组件eventBus全局传值
import { globalData } from '@/views/teacherCard/eventBus/eventBus.js'
export default {
  methods: {
    activation () {
      this.isLoading = !this.isLoading
      this.$http({
        url: '/api/v1/card/activate',
        method: 'post',
        data: this.form
      }).then(res => {
        // 处理返回结果
        console.log(res);
        if (res.status === 200) {
          this.activeStep = 2;
          // this.$message.error(('验证成功:' + res.msg));
          console.log('卡号：' + res.data.card_id);
          // 将card_id传递给eventBus
          globalData.cardId = res.data.card_id;
        } else {
          this.$message.error(res.msg);
        }
      })
    },
    // 切换高中或者初中
    handleSelectChange () {
      const isDev = process.env.NODE_ENV === 'development'
      if (isDev) { // 本地
        if (this.value === "初中") {
          location.href = `${process.env.VUE_APP_BASE_URL_cz}/#/teacherCard`;
        } else if (this.value === "高中") {
          // console.log(process.env.VUE_APP_BASE_URL_gz)
          location.href = `${process.env.VUE_APP_BASE_URL_gz}/#/teacherCard`;
        }
      } else {
        // 生产环境
        if (this.value === "初中") {
          location.href = `${process.env.VUE_APP_BASE_URL_cz}/#/teacherCard`;
        } else if (this.value === "高中") {
          location.href = `${process.env.VUE_APP_BASE_URL_gz}/#/teacherCard`;
        }
      }

    },
    goToStep3 () {
      this.activeStep += 1; // 进入第下一步绑定
    },
    back () {
      this.$router.push("/login")
    },
    // 返回上一步
    to () {
      this.activeStep -= 1;
    },
  }
}