<template>
  <div class="right_form">
    <div class="back_left">
      <img src="@/assets/teachterCard/Left.png"
           @click="$emit('Back')"
           alt="" />
    </div>
    <div class="block">
      <div class="title">
        绑定手机号码
      </div>
    </div>
    <div class="form">
      <el-form ref="phoneForm"
               :model="phoneFormData">
        <el-form-item prop="mobile">
          <div class="line">
            <el-input v-model="phoneFormData.mobile"
                      placeholder="请输入手机号码"></el-input>
            <div class="line_tip">手机号码绑定后，可用作教师端登录账号</div>
          </div>
        </el-form-item>
        <el-form-item prop="code">

          <div class="line1">
            <el-input clearable
                      v-model="phoneFormData.code"
                      placeholder="请输入验证码"
                      autocomplete="off">
            </el-input>
            <el-button type="text"
                       @click="sendCode">{{
                  getCodeBtnText
                }}</el-button>

          </div>

        </el-form-item>
        <el-form-item>
          <div class="line">
            <el-input clearable
                      placeholder="请输入密码"
                      type="password"
                      v-model="phoneFormData.password"
                      show-password>
            </el-input>
          </div>
        </el-form-item>
        <el-form-item>
          <div class="line">
            <el-input clearable
                      placeholder="请再次输入密码"
                      type="password"
                      v-model="phoneFormData.password_again"
                      show-password>
            </el-input>
          </div>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     class="nxet-btn2"
                     :loading="isLoading"
                     @click="submitPhoneForm"
                     :disabled="!phoneFormData.mobile || !phoneFormData.password||!phoneFormData.password_again||!phoneFormData.code">下一步
          </el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script>
import { globalData } from '@/views/teacherCard/eventBus/eventBus.js'
export default {
  data () {
    return {
      isLoading: false,
      activeStep: 2,
      phoneFormData: {
        mobile: '',
        password: '',
        password_again: '',
        code: '',
        countdown: 60,//验证码剩余秒数
        isSendingCode: false,
      },
      cardId: ''
    }
  },
  mounted () {

  },
  //获取验证码
  computed: {
    getCodeBtnText () {
      if (this.phoneFormData.isSendingCode) {
        return `${this.phoneFormData.countdown}s后重新获取`;
      }
      return '获取验证码';
    },
    getCodeBtnDisabled () {
      return this.phoneFormData.isSendingCode || this.phoneFormData.countdown > 0;
    }
  },
  methods: {
    // 发起获取验证码
    sendCode () {
      const mobile = this.phoneFormData.mobile;
      if (!/^1[3456789]\d{9}$/.test(mobile)) {
        this.$message.error('请填写正确的11位数手机号');
        return;
      }
      this.phoneFormData.isSendingCode = true;
      this.$http({
        url: '/api/v1/card/verify',
        method: 'post',
        params: { mobile }
      }).then(res => {
        if (res.status === 200) {
          this.$message.success('验证码发送成功，请注意查收');
          let countdown = this.phoneFormData.countdown;
          const timer = setInterval(() => {
            countdown--;
            this.phoneFormData.countdown = countdown;
            if (countdown <= 0) {
              clearInterval(timer);
              this.phoneFormData.countdown = 60;
              this.phoneFormData.isSendingCode = false;
            }
          }, 1000);
        } else {
          this.phoneFormData.isSendingCode = false;
          this.$message.error('验证码发送失败：' + response.data.msg);
        }
      })
        .catch(error => {
          this.phoneFormData.isSendingCode = false;
        });
    },
    submitPhoneForm () {
      // 验证手机号码和验证码
      const cardId = globalData.cardId;
      console.log(cardId);
      if (!this.phoneFormData.password) {
        this.$message.error("请输入密码");
        return;
      } else if (!this.phoneFormData.password_again) {
        this.$message.error("请再次输入密码");
        return;
      } else if (this.phoneFormData.password !== this.phoneFormData.password_again) {
        this.$message.error("两次密码不一致");
        return;
      } else if (this.phoneFormData.password.length < 6 || this.phoneFormData.password.length > 12) {
        this.$message.error("密码长度必须是6到12位");
        return;
      }
      this.$refs.phoneForm.validate(valid => {
        if (valid) {
          // 发起HTTP POST请求
          this.$http({
            url: '/api/v1/card/activate_code',
            method: 'post',
            data: {
              mobile: this.phoneFormData.mobile,
              code: this.phoneFormData.code,
              password: this.phoneFormData.password,
              card_id: globalData.cardId
            }
          }).then(res => {
            if (res.status === 200) {
              this.$emit('step-success'); // 验证成功，通知父组件
              globalData.unqid = res.data.unqid;  // 将uniqid传递给全局eventBus
            } else {
              this.$message.error('验证码错误');
            }
          })
        }
      });
    }
  },
  destroyed () {
    clearInterval(this.timer);
  },
}
</script>
<style lang="scss" scoped>
.right_form {
  width: 50%;
  .back_left {
    padding: 16px 0 0 16px;
    box-sizing: border-box;
    cursor: pointer;
    display: inline-block;
  }
  .title {
    display: flex;
    justify-content: center;
    height: 39px;
    font-size: 28px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 600;
    color: #1a1a1a;
    margin-top: 10px;
  }

  .form {
    display: flex;
    justify-content: center;
    margin-top: 53px;

    .line {
      width: 100%;
      .line_tip {
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 22px;
      }
    }
    .line1 {
      display: flex;
    }
    .custom-cascader {
      .el-cascader__dropdown {
        width: 100%;
        max-height: 100%;
      }
    }
  }
}
::v-deep .el-input__prefix {
  left: 9px;
  margin-top: 11px;
}

::v-deep .el-input__inner {
  font-size: 14px !important;
}
::v-deep .el-input--medium .el-input__inner {
  height: 40px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-radius: 0%;
  line-height: 40px;
}
::v-deep .el-form-item__content {
  width: 100%;
}
::v-deep .nxet-btn2 {
  margin: 0 auto;
  width: 336px;
  height: 48px;
  cursor: pointer;
  margin-top: 24px;
  border-radius: 5px;
  font-size: 16px;
}
::v-deep .el-form-item {
  margin-bottom: 16px;
}
</style>
