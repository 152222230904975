<template>
  <div class="SC_context">
    <div class="Sc_img">
      <img src="@/assets/teachterCard/成功.png"
           alt="">
    </div>
    <div class="SC_prompt">
      你已成功激活教师卡
    </div>
    <div class="SC_back">

      {{count}}s后会回到教师端登录页，如果没有请
      <span>
        <el-button type="text"
                   @click="tologin">点击这里</el-button>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      count: ''
    }
  },
  created () {
    this.goChoicePeople()
  },
  methods: {
    goChoicePeople () {
      const TIME_COUNT = 3
      if (!this.timer) {
        this.count = TIME_COUNT,
          this.show = false,
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--
            } else {
              this.show = true
              clearInterval(this.timer)
              this.timer = null
              // 跳转到首页
              this.$router.push('/login')
            }
          }, 1000)
      }
    },
    tologin () {
      this.$router.push('/login')
    }
  },
  destroyed () {
    clearInterval(this.timer);
  },
}
</script>
<style lang="scss" scoped>
.SC_context {
  width: 50%;
  margin-top: 48px;
  .Sc_img {
    display: flex;
    justify-content: center;
    align-items: center;
    img {
    }
  }
  .SC_prompt {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 600;
    color: #333333;
    line-height: 23px;
  }
  .SC_back {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 14px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #999999;
    line-height: 22px;
  }
}
::v-deep .el-button--text {
  color: #333333;
}
</style>
